@tailwind base;

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }

  h2 {
    @apply text-2xl font-semibold;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  h4 {
    @apply text-xl font-medium;
  }

  h5 {
    @apply text-lg font-medium;
  }

  h6 {
    @apply text-base font-medium;
  }
}

/* h1 {
  @apply text-4xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
} */

@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bricolage-grotesque-bold {
  font-family: "Bricolage Grotesque", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.bricolage-grotesque-semibold {
  font-family: "Bricolage Grotesque", serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

.kaushan-script-regular {
  font-family: "Kaushan Script", serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-regular-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}

@layer base {
  @font-face {
    font-family: "GT-America-Mono-Regular-Trial";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/public/font/GT-America-Standard-Regular-Trial.otf")
      format("opentype");
  }

  @font-face {
    font-family: "GT-America-Mono-Medium-Trial";
  font-style: medium;
  font-weight: 500;
  font-display: swap;
  src: url("/public/font/GT-America-Standard-Medium-Trial.otf")
    format("opentype");
  }
}

.font-gtAmerica {
  font-family: "GT-America-Mono-Regular-Trial";
}

.font-gtAmerica-medium {
  font-family: "GT-America-Mono-Medium-Trial";
}

.secondary-btn-shadow {
  box-shadow: 0px 10px 16px 0px rgba(0, 0, 0, 0.1);
}

@layer components {
  .transition-custom {
    @apply transition-all duration-[300ms];
  }
}

.swiper-pagination-progressbar {
  background: #876ebc !important;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #ffffff !important;
}

.custom-gradient {
  background: linear-gradient(to top, #301645, #301645 0%, transparent);
}

@layer utilities {
  .custom-gradient2 {
    background: linear-gradient(to top, #8d19da, #f20b97);
  }

  .custom-gradient3 {
    background: linear-gradient(to top, #992727, #ff5d5d);
  }

  .bg-gradient {
    background: linear-gradient(to right, #0D0718 100%, #0F081B 0% , #0B0614 100%);
  }

  .card-gradient {
    background: linear-gradient(to right, #8d19da, #f20b97 89%, #8d19da);
  }

  .text-gradient {
    background-image: linear-gradient(to right, #f20b97, #8d19da);
    background-clip: text;
    -webkit-background-clip: text; /* Safari & Chrome */
    -moz-background-clip: text; /* Firefox */
    -o-background-clip: text; /* Opera (older versions) */
    -ms-background-clip: text; /* Internet Explorer (limited support) */
    color: transparent;
    -webkit-text-fill-color: transparent;
  }
}

@keyframes pos-spin1 {
  to {
    transform: rotate(360deg);
  }
}

.positive-animate-spin1 {
  animation: pos-spin1 8s linear infinite;
}

@keyframes -spin1 {
  to {
    transform: rotate(-360deg);
  }
}

.-animate-spin1 {
  animation: -spin1 8s linear infinite;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeIn1 {
  animation: fade 1s linear forwards;
}

.fadeIn2 {
  animation: fade 1s linear 1s forwards;
}

.fadeIn3 {
  animation: fade 1s linear 2s forwards;
}

.icon-shadow {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

@keyframes popup {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.1);
  }
}

.popup {
  animation: popup 1s ease-in-out infinite alternate;
}

.lnr {
  animation: lnr 5s linear infinite;
}
.lnr-2 {
  animation: lnr2 5s linear infinite;
}

@keyframes lnr {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-80px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes lnr2 {
  0% {
    transform: translateY(-80px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-80px);
  }
}
